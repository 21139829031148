import { IUserInfo } from "../connectors/SocketConnector";
import UserConnector from "../connectors/UserConnector";
import TransportInterface from "../transport/TransportInterface";
import BgServerFb from "./BgServerFb";
import { engineVersion } from "mobile-device-detect";



export default class UserConnectorFacebook extends UserConnector {
    server:BgServerFb

    constructor(socket:SocketIOClient.Socket, transport:TransportInterface, server:BgServerFb)  {                
        super(socket,transport)
        this.server = server;

        this.socket.on('connect', ()=>{            
            this.doUserInfoGet(); //send cookies
        });
    }

    async doUserInfoGet():Promise<any> {        
        let result = await this.server.backendRequest({            
            login : {
                signedRequest : this.server.signedRequest,
            },
            initial : true,
            instant : true,
            profile : {
                short_name : FBInstant.player.getName(),
                picture : {
                    data : {
                        url : FBInstant.player.getPhoto(),
                    },
                },
            },
        })        
        this.checkSessionData(result); 
        this.__onUserInfo(result);         
        //this.userInfoAjax = result;
        //this.checkUserInfoReady();

        return result;        
    }


}