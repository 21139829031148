import { IAccount } from "./Account";
import { EGameKind } from "./Game";


export enum EBotSkillLevel {
    DUMMY     =  "dummy",       
    NOVICE    =  "novice",      
    SIMPLE    =  "simple" ,     
    MIDDLE    =  "middle" ,     
    ADVANCED  =  "advanced",    
    PROFESSIONAL = "professional",
    EXPERT    =  "expert",    
    MASTER    =  "master" ,   
    CHAMPION  =  "champion" , 
    GURU      =  "guru",  
}


export enum EGameSpeed {
    EXPRESS = "express",
    FAST = "fast",
    NORMAL = "normal", 
    SLOW = "slow", 
    LONG = "long"
}

export enum EFlagColor {
    ANY = "any",
    RED = "red",
    BLUE = "blue",
}

export enum EChipColor {
    ANY = "any",
    WHITE = "white",
    BLACK = "black",
}

export enum EFeatureAutoDice {
    DISABLED= "disabled",
    TRUE = "true",
}

export enum EFeatureDoubleClickMoveType {
    DISABLED = "disabled",
    SINGLE = "single",
    SMALL_FIRST = "small_first",
    BIG_FIRST = "big_first",
    SMART = "smart",
}


// static $FEATURE_AUTO_BEARING_OFF = [
//     "disabled" => "Manual",
//     "auto"     => "Automatic",
// ];

export enum EFeatureAutoBearingOff {
    DISABLED = "disabled",
    AUTO = "auto",
}



// static $FEATURE_AUTO_TURN = [
//     "immediately"   => "immediately turn",
//     "wait"          => "2 seconds wait",
//     "manual"        => "Manual turn",
// ];


export enum EFeatureAutoTurn {
    IMMEDIATELY = "immediately",
    WAIT = "wait",
    MANUAL = "manual",    
}







export interface IPlayerSettingsGameCreate {
    skill_level: EBotSkillLevel 
    
    bet_currency:string | "Cr"
    bet_price:number
    caption:string
    double_cube_auto:boolean
    double_cube_auto_limit:number
    double_cube_biver:boolean
    double_cube_enable:boolean
    double_cube_rakun:boolean
    enable_chat:boolean
    enable_crawford:boolean
    enable_jakobi:boolean
    enable_koks:boolean
    enable_mars:boolean
    enable_paritet:boolean
    enable_watchers:boolean
    game_type:EGameKind
    max_score:number
    opponent_rating_score_max:number
    opponent_rating_score_min:number
    password:string
}

export interface IPlayerSettings {
    account_chip:IAccount
    account_desk:IAccount
    avatar_type:number
    color: EChipColor 
    country_code: string

    feature_auto_bearing_off: EFeatureAutoBearingOff 
    feature_auto_dice: EFeatureAutoDice 
    feature_auto_turn: EFeatureAutoTurn
    feature_double_click_move_type: EFeatureDoubleClickMoveType
    flag_color: EFlagColor

    sound_enbale:boolean //!! sound_enabled
    game_create:IPlayerSettingsGameCreate
    lang:string
    nickname?:string
}


// static $COLOR_VARIANTS = [
//     "black" => "Black",
//     "white" => "White",
//     "any"   => "I don't care about color",
// ];

// static $FLAG_COLOR_VARIANTS = [
//     "red" => "Red",
//     "blue" => "Blue",
// ];

// static $SKIN_TYPE_VARIANTS = [
//     "standard" => "Standard",
//     "standard+" => "Standard+",
// ];


// static $COUNTRY_CODES = [
//     "RU"   => "Russian Federation (the)",
//     "BY"   => "Belarus",
//     "UA"   => "Ukraine",
//     "KZ"   => "Kazakhstan",
//     "US"   => "United States of America (the)",
//     "GB"   => "United Kingdom of Great Britain and Northern Ireland (the)",
//     "DE"   => "Germany",
//     "FR"   => "France",
//     "TR"   => "Turkey",
//     "CN"   => "China",
//     "ES"     => "Spain",
//     "IT"     => "Italy",
//     "NL"     => "Netherlands (the)",
//     "SE"     => "Sweden",
// ];

// settings:
// account_chip: {uuid: "", currency: {…}}
// account_desk: {uuid: "", currency: {…}}
// avatar_type: 999
// color: "any"
// country_code: "ru"
// feature_auto_bearing_off: "auto"
// feature_auto_dice: "true"
// feature_auto_turn: "wait"
// feature_double_click_move_type: "big_first"
// flag_color: "red"
// game_create: {skill_level: "novice", speed_level: "normal", play_type: 0, game_type: 1, time_for_one_step: 180, …}
// lang: "en"
// m_player: null
// other: null
// sound_enable: true
// __proto__: Object