import { ISearchPagination, ISearchResult } from "../connectors/SocketConnector";
import TradeConnector from "../connectors/TradeConnector";
import ModelCollectionTrade from "../model/ModelCollectionTrade";
import TradeOffer, { ITradeOffer, ITradeOfferWithCurrency , TradeOfferUuid } from "../model/Types/TradeOffer";
import { ETradeOfferTypeId } from '~/modules/model/Types/TradeOffer'
import logger from "../utility/logger";
import sha256 from 'crypto-js/sha256'
import CryptoJS from 'crypto-js'
import { v4 as uuidv4 } from 'uuid';


const  ROBOKASSA_PASSWORD_1 = process.env.ROBOKASSA_PASSWORD_1;
 
export default class TradeConnectorRobokassa extends TradeConnector {
    trade_offers : { [ key : string ]: TradeOffer } = {}

    async doGetListSearch(search:ITradeOffer | {}, pagination:ISearchPagination):Promise< ISearchResult < ITradeOfferWithCurrency >> {        
        const result = await super.doGetListSearch( search, pagination )
        console.log( 'Trade result:', result )
        if ( result && result.models )
            for ( const m of result.models )
                this.trade_offers[ m.uuid ] = new TradeOffer( m as ITradeOfferWithCurrency )
        return result
    };
    
    async doExecute(uuid:TradeOfferUuid) {
        const tradeOffer = this.trade_offers[ uuid ]
        if ( ! tradeOffer ) {
            logger.error( 'Trade offer with uuid', uuid, 'NOT found' )
            throw Error( 'Trade offer with uuid ' + uuid + ' NOT found' )
        }

        if ( ! tradeOffer.isCoinsBuy() )
            return await super.doExecute( uuid )
        
        invoke_robokassa_payment(
            {
                user_id : this.myUuid || 'undefined',
                message : 'hello there',
                coins : tradeOffer.value_to.toString(),
                trade_offer_uuid : tradeOffer.uuid,
                payment_id : uuidv4(),
            },
            tradeOffer.value_from / 100,
        )
    };
}

type UserData = { [ key : string ] : string }

function invoke_robokassa_payment( user_data : UserData, rubles : number ) {
    console.log( 'Executing Robokassa trade offer with user data:', user_data )
    const altered_user_data : UserData = {}
    for ( const key in user_data )
        altered_user_data[ 'shp_' + key ] = user_data[ key ]

    const settings : any = {
        MerchantLogin: '5sbackgammon',
        OutSum: rubles + '.00',
        //Description: 'Покупка кредитов ©Backgammon 5Stars',
        //shp_Item: '1',
        //Encoding: 'utf-8',
        InvId : 0,
        //IsTest : 1,
        ... altered_user_data,
    }
    
    sign( settings, ROBOKASSA_PASSWORD_1 as string, altered_user_data )
    console.log( 'ROBOKASSA final settings:', settings )

    //@ts-ignore
    Robokassa.StartPayment( settings )
}

function sign(
    settings : any,
    password : string,
    user_data : UserData
) {
    const signature_values = [
        settings.MerchantLogin,
        settings.OutSum,
        settings.hasOwnProperty( 'InvId' ) ? settings.InvId : '',
        password,
    ]

    //adding user_data if it's present to signature source:
    const user_data_signature_source = make_user_data_signature_source( user_data )
    if ( user_data_signature_source.length > 1 )
        signature_values.push( user_data_signature_source )

    const signature_source = signature_values.join( ':' )
    settings.SignatureValue = sha256( signature_source ).toString( CryptoJS.enc.Hex )
    console.log( 'ROBOKASSA signature:', signature_source, '->', settings.SignatureValue )
}

function make_user_data_signature_source( user_data : UserData ) {
    const params = []
    //has to be in alphabetical order:
    const keys = Object.keys( user_data )
    keys.sort()
    for ( const key of keys )
        params.push( key + '=' + user_data[ key ] )
    return params.join( ':' )
}
