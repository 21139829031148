import { ISearchPagination, ISearchResult } from "../connectors/SocketConnector";
import TradeConnector from "../connectors/TradeConnector";
import ModelCollectionTrade from "../model/ModelCollectionTrade";
import TradeOffer, { ITradeOffer, ITradeOfferWithCurrency, TradeOfferUuid } from "../model/Types/TradeOffer";
import BgServerMailru from "./BgServerMailru";
import { ETradeOfferTypeId } from '~/modules/model/Types/TradeOffer'
import logger from "../utility/logger";
import { space } from '../../modules/space'


/** Inherited from TradeConnectorYandex */
export default class TradeConnectorMailru extends TradeConnector {
    server:BgServerMailru

    tradeOfferList: ModelCollectionTrade

    constructor( socket : SocketIOClient.Socket, server : BgServerMailru ) {
        super(socket);
        this.server = server;

        this.tradeOfferList = new ModelCollectionTrade()
    }

    async doGetListSearch(search:ITradeOffer, pagination:ISearchPagination):Promise< ISearchResult < ITradeOfferWithCurrency >> {                        
        const result = await super.doGetListSearch(search, pagination)

        logger.log( 'doGetListSearch should look like this:', result.models )

        const models = filter_offers( result.models )
                                    
        //I couldn't understand this code and it causes problems:
        /*// if search is empty
        if(Object.keys(search).length !== 0) {        */
            this.tradeOfferList.clear();
            this.tradeOfferList.itemUpdateArray(models);            
        //}                

        return {...result, models}        
    };

    async doExecute(uuid:TradeOfferUuid) {
        let tradeOffer = this.tradeOfferList.get(uuid);

        if( !tradeOffer || !tradeOffer.isMailru() || !tradeOffer.product_id ) 
            return super.doExecute(uuid);
        
        await this.wrapLoading( () => this.doExecuteMailru(tradeOffer) )
    };

    async doExecuteMailru(tradeOffer:TradeOffer | undefined) {
        if(!tradeOffer || !tradeOffer.product_id)
            return;
        
        space.s( 'mailru:initiate_purchase' ).next( ({ tradeOffer, uuid : this.myUuid }) )
    }
}

function filter_offers( offers : ITradeOfferWithCurrency[] ) {
    //@ts-ignore
    const mailru_currency : string = mailru.params.currency || "RUB"
    let matched = false
    function filter_function( currency : string ) {
        return ( tradeOffer : ITradeOfferWithCurrency ) => {
            if ( tradeOffer.currencyFrom.code_name == currency ) {
                matched = true
                return true
            }
            return false
        }
    }
    //letting only currently specified target currency:
    let result = filter_out_currency(
        offers,
        filter_function( mailru_currency ),
    )

    if ( ! matched ) {
        console.warn( "Mailru: trade offers for user's currency", mailru_currency, "was NOT resolved. Switching to USD ..." )
        result = filter_out_currency(
            offers,
            filter_function( "USD" ),
        )
    }

    if ( ! matched ) {
        console.warn( "Mailru: trade offers was NOT resolved for USD. Switching to RUB ..." )
        result = filter_out_currency(
            offers,
            filter_function( "RUB" ),
        )
    }

    if ( ! matched ) {
        console.error( "Mailru: trade offers was NOT resolved even for RUB." )
    }

    console.log( "Mailru: resulting trade offers:", result )

    return result
}

function filter_out_currency(
    offers : ITradeOfferWithCurrency[],
    filter : ( offer : ITradeOfferWithCurrency ) => boolean,
) {
    return offers.filter( ( tradeOffer : ITradeOfferWithCurrency ) => {
        if ( tradeOffer.type_id != ETradeOfferTypeId.PURCHASE_MAILRU )  {
            return true
        }
        return filter( tradeOffer )
    })
}

