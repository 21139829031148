import BgServer from "../BgServer";
import { NuxtAxiosInstance } from "@nuxtjs/axios";

import TransportAxios from "../transport/TransportAxios";
import UserConnectorFacebook from "./UserConnectorFacebook";
import TradeConnectorFacebook from "./TradeConnectorFacebook";

import { Context } from '@nuxt/types'
import logger from "../utility/logger";
import ModelListCommon from "../ModelListCommon"
import ArraySortedPlayer from "../ArraySortedPlayer";
import TradeConnector from "../connectors/TradeConnector";

export default class BgServerFb extends BgServer {
    context:Context
    /** Facebook related data to pass to bg_pay to verify player */
    signedRequest : any
    purchaseEnable = false

    constructor(axios: NuxtAxiosInstance, context: Context) {
        super(axios);
        this.context = context
    }

    async init() {
        await this.initSDK();
        await super.init();
    }
    
    createUserConnector(transport:TransportAxios) {
        return new UserConnectorFacebook(this.socket, transport,this);       
    }
    
    initConnectorTrade() {                
        const connector : TradeConnector = new TradeConnectorFacebook(this.socket, this)
        connector.verbose = process.env.DEBUG_VERBOSE == "true"
        return connector  
    }

    async initSDK() {
        console.log( 'Facebook: initializing API ...' )
        await FBInstant.initializeAsync()
        console.log( 'Facebook: API successfully initialized.' )
        //doesn't work due to cryptic "NETWORK_FAILURE" error:
        await this.initSignature()

        /* Facebook complaint:
Code
```
await FBInstant.initializeAsync()
await FBInstant.player.getSignedPlayerInfoAsync()
```
fails with error:
```
{code: 'NETWORK_FAILURE', message: 'Failed to retrieve signed player information'}
```
I tried clearing cookies;storage;cache. Trying to reimplement my game after a year-long period when game was working fine. Maybe some API that needs to be {re}activated in developer console I'm missing?
Thanks in advance!
*/

        const sdkVersion = FBInstant.getSDKVersion(); // '3.0'
        const playerID = FBInstant.player.getID()
        logger.log("FB sdkVersion", sdkVersion)
        logger.log("FB playerID"  , playerID)
        logger.log('Facebook player:', FBInstant.player, 'id:', playerID )
        
        //this.context.store.commit("SET_SHOW_ADVICER", true)
        this.discoverSupportedAPI()
        this.setupLocale()
        this.setupPlatform()       
        
        this.doAnimateLoading();
    }

    async initSignature() {
        console.log( 'Facebook: obtaining player signature ...' )
        const info = await FBInstant.player.getSignedPlayerInfoAsync()
        console.log( 'Facebook: player signature successfully obtained.' )
        this.signedRequest = info.getSignature()        
    }

    discoverSupportedAPI() {
        let apiList = FBInstant.getSupportedAPIs();
        this.initSupportPurchases(apiList);
    }

    initSupportPurchases(apiList:string[]) {
        // see https://developers.facebook.com/docs/games/instant-games/guides/in-app-purchases/
        let isPurchasesExist = apiList.includes( "payments.purchaseAsync" )
        if( ! isPurchasesExist ) {
            console.error( 'Facebook: api list does NOT include purchaseAsync API.' )
            return
        }

        FBInstant.payments.onReady(() => {
            console.log( 'Facebook: Payments Ready.' )
            this.purchaseEnable = true;
            this.context.store.commit("SET_PURCHASE_ENABLE", this.purchaseEnable);
        });                
    }

    setupPlatform() {
        let platform = FBInstant.getPlatform(); // 'IOS'
        logger.log("FB platform"  , platform)   
        this.context.store.commit("SET_PLATFORM", platform)
    }

    setupLocale() {
        let locale = FBInstant.getLocale(); // 'en_US'                
        if(!locale)
            return;

        let localeShort = locale.substring(0,2);
        logger.log("Set locale from fb" , locale, localeShort);
        let i18n = (<any>this.context.app.i18n)        
        if(!i18n) {
            logger.warn("Not found i18n module");            
            return;
        }

        i18n.setLocale(localeShort);                
        i18n.setLocaleCookie(localeShort);                                 
    }
       
    async backendRequest(data:any ) {
        const BG_PAY_ENDPOINT = process.env.BG_PAY_ENDPOINT || ''

        data.app_id = this.extractAppId()

        // post request to bg_pay server
        let result = await this.axios.post("", data, {
            baseURL: BG_PAY_ENDPOINT,
            withCredentials:true ,
            headers: {
                "Authorization" : "bgsession "+this.connectorUser.sessionId,
            }      
        });

        return result.data;                        
    }

    extractAppId() {
        const FACEBOOK_APP_ID = process.env.FACEBOOK_APP_ID
        if(FACEBOOK_APP_ID != "auto")
            return FACEBOOK_APP_ID;
        // example https://apps-703720776794877.apps.fbsbx.com        
        let location = window.location.href;        
        //https://apps-703720776794877.apps.fbsbx.com/instant-bundle/4202487869821368/3225095637556924/index.html?version=15&gcgs=1&source=fbinstant-345745919921358&entry_point=fb_gg_url&IsMobileWeb=0&cloud_binary_id причем доставать надо не из дмена а то что после source=fbinstant-345745919921358
        const PREFIXER = "source=fbinstant-";
        let begin = location.indexOf(PREFIXER);
        if(begin<0)
            return;
        begin+=PREFIXER.length;

        let tempString = location.slice(begin);
        let chunks = tempString.split("&");
        return chunks[0]                                
    }


    loadingTimer:NodeJS.Timeout| null = null;
    loadingProgress: number = 0;
    loadingProgressMax: number = 99;

    doAnimateLoading() {
        // if(this.loadingTimer)
        //     clearInterval(this.loadingTimer);
        if(this.loadingTimer)
            return;

        this.loadingTimer = setInterval( ()=>{
            if(this.loadingProgress < this.loadingProgressMax) {
                this.loadingProgress++;
                FBInstant.setLoadingProgress(this.loadingProgress)
            }                                    
        }, 300);
    }

    async onLoadFinish() { 
        // TODO wait user_info fully populated
        await this.doWaitPlayerPopulated();

        if(this.loadingTimer) {
            clearInterval(this.loadingTimer);
            this.loadingTimer =  null;
        }

        if(this.modelPlayer.models.player) {
            await FBInstant.startGameAsync()

        } else {

        }                
    }

    async doWaitPlayerPopulated() {
        if(this.modelPlayer.models.player)
            return;                      
        return new Promise<void>( (resolve,reject) => {            
            this.modelPlayer.on(ModelListCommon.EVENT_DATA_POPULATED, (list:ArraySortedPlayer)=>{
                if(list.player)
                    resolve();
            });
        })         
    }


    async saveSessionId(sessionId:string) { 
        try {
            await FBInstant.player.setDataAsync({                
                sessionId
            });
        } catch(e) {
        }        
    }

    async restoreSessionId(){        
        try {
            let data = await FBInstant.player.getDataAsync(["sessionId"]);
            let sessionId = data["sessionId"]            
            this.connectorUser.setSessionId(sessionId);            
        } catch(e) {
        }        
    }

}




// setPlatform  ("IOS" | "ANDROID" | "WEB" | "MOBILE_WEB")

//I guess Nuxt guarantees app completely loaded, right?
/** 
 * ProninE: Nope.. We should wait antil window.onload event fires
 * And maybe we should have all pages hidden 
 * - so window can loads all css styles and assets
 * 
 * so startGameAsync moved to onLoadFinish
*/ 
        
/* tasks:
    - залогинен на наш сервер
    - залогинен на фэйсбук
    - НЕ залогинен на наш сервер
    - НЕ залогинен на фэйсбук
    - залогинен на фэйсбук, но не заапрувил нашу
*/
/*
    backgammon server (PHP+Node.js) (bg_server)
    Facebook server
    bg_pay
    backgammon client
*/
/* Facebook auth:
    //general flow (+Login button):
    client -> Facebook -> client -> bg_pay (playerID) -> bg_server -> client -> 

    //Facebook Instant Games only:
    client(startGameAsync) -> Facebook(playerID) -> client -> bg_server(playerID)


    //general flow (+Login button):
    client -> Facebook -> client ->| bg_pay (playerID)| -> bg_server -> client -> 
*/        