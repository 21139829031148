import { ISearchPagination, ISearchResult } from "../connectors/SocketConnector";
import TradeConnector from "../connectors/TradeConnector";
import ModelCollectionTrade from "../model/ModelCollectionTrade";
import TradeOffer, { ITradeOffer, ITradeOfferWithCurrency, TradeOfferUuid } from "../model/Types/TradeOffer";
import BgServerFb from "./BgServerFb";
import { ETradeOfferTypeId } from '~/modules/model/Types/TradeOffer'
import logger from "../utility/logger";



export default class TradeConnectorFacebook extends TradeConnector {
    server:BgServerFb

    tradeOfferList: ModelCollectionTrade

    constructor(socket:SocketIOClient.Socket, server:BgServerFb) {
        super(socket);
        this.server = server;

        this.tradeOfferList = new ModelCollectionTrade()

        //also passively try to consume any hanging purchases (in case there was previosly network failure, power outage, etc):
        this.autoConsumePurchases()
    }
    

    async getFBCatalog() {
        let result:FBInstant.Product[] = [];

        if(!this.server.purchaseEnable)
            return result;
        await this.wrapLoading( async ()=>{
            try {
                result = await FBInstant.payments.getCatalogAsync();
            } catch(e) {
                logger.error("FBInstant getCatalogAsync error", e)
                // maybe set in store
            }
        });
        return result;
    }

    

    async doGetListSearch(search:ITradeOffer, pagination:ISearchPagination):Promise< ISearchResult < ITradeOfferWithCurrency >> {                
        let [ result, facebook_result ] = await Promise.all( [ 
            super.doGetListSearch(search, pagination), 
            this.getFBCatalog()        
        ]);

        logger.log( 'doGetListSearch should look like this:', result.models )
        logger.log( '    ... and Facebook products:', facebook_result )

        let models = this.mergeTradeOffers(result.models, facebook_result );
                                    
        // if search is empty
        if(Object.keys(search).length !== 0) {        
            this.tradeOfferList.clear();
            this.tradeOfferList.itemUpdateArray(models);            
        }                

        return {...result, models}        
    };
    

    private mergeTradeOffers(tradeOffers: ITradeOfferWithCurrency[], fbProducts:FBInstant.Product[]) {
        //merge Facebook items into our server's data:        
        let offers = tradeOffers.filter(tradeOffer => {
            if ( tradeOffer.type_id != ETradeOfferTypeId.PURCHASE_FACEBOOK ) 
                return true;
            
            let product = fbProducts.find( product => product.productID == tradeOffer.product_id );
            if(!product) {
                logger.warn("Facebook's product equivalent was NOT resolved for product: "+ JSON.stringify( tradeOffer ));
                return false;
            }            
            tradeOffer.force_price_from = {
                price : product.price,
                currency_code : product.priceCurrencyCode,
            }  
            return true;          
        });

        return offers;
    }

    async doExecute(uuid:TradeOfferUuid) {
        let tradeOffer = this.tradeOfferList.get(uuid);

        if( !tradeOffer || !tradeOffer.isFacebook() || !tradeOffer.product_id ) 
            return super.doExecute(uuid);
        
        await this.wrapLoading( () => this.doExecuteFacebook(tradeOffer));       
    };

    async doExecuteFacebook(tradeOffer:TradeOffer | undefined) {
        if(!tradeOffer || !tradeOffer.product_id)
            return;

        logger.log( 'Initiating Facebook purchase ...' )
        try {
            const purchase = await FBInstant.payments.purchaseAsync({ 
                productID : tradeOffer.product_id,
                developerPayload : JSON.stringify({
                    player_uuid : this.myUuid,
                }),
            })
            logger.log( 'Facebook Purchase:', purchase )
            await this.autoConsumePurchases( [ purchase ] ) 
        }
        catch ( e ) {
            //ok: player just cancelled deal:
            if ( e.code == 'USER_INPUT' )
                return "no I didn't purchased"
            throw e
        }       
    }

    //TODO: call at any reconnections or at timeout after previuos Facebook network malfunctions:
    async autoConsumePurchases( purchases : FBInstant.Purchase[] = [] )
    {
        try {
            if ( ! purchases || purchases.length <= 0 )
                purchases = await FBInstant.payments.getPurchasesAsync()
        }
        catch(e) {
            //probably connector error: re-request some time later:
            logger.error( 'Facebook purchases retrieval failed:', e, 'Retrying in 5 secs ...' )
            setTimeout( () => this.autoConsumePurchases(), 5000 )
            return
        }

        purchases = purchases.filter( p => p.isConsumed != true )

        logger.log( 'Facebook purchases yet to be consumed:', purchases )
        if ( ! purchases || purchases.length <= 0 )
            return
        
        let result
        try {
            result = await this.server.backendRequest({
                //login : facebook_login,
                login : {
                    signedRequest : this.server.signedRequest,
                },
                purchases,
            })
        }
        catch(e) {
            logger.error( 'Facebook purchases consuming failed:', e, 'Retrying in 5 secs ...' )
            setTimeout( () => this.autoConsumePurchases(), 5000 )
            return
        }

        logger.log( 'Facebook purchases should be successfully consumed:', result )

        if ( result.message == 'handle_purchases' ) {
            for ( const p of result.purchases ) {
                if ( p.isConsumed != false )
                    continue
                
                let p_result
                try {
                    p_result = await FBInstant.payments.consumePurchaseAsync( p.purchaseToken )
                    logger.log( 'Facebook purchase', p, 'successfully consumed:', p_result )
                }
                catch(e) {
                    logger.log( 'Facebook purchase consuming failed:', e )
                    continue
                }
            }
        }
    }
} 