import ModelCollection from "./ModelCollection"

import Account from "./Types/Account";

export default class ModelCollectionAccount extends ModelCollection<Account>  {
    
    constructor() {
        super();        
    }

    itemDead(model:Account) { 
        return false; 
    }

    itemCreate(data:object):Account { 
        return new Account(data);
    }            
}