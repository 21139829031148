/** \file copy of UserConnectorYandex for now */

import { IUserInfo } from "../connectors/SocketConnector";
import UserConnector from "../connectors/UserConnector";
import TransportInterface from "../transport/TransportInterface";
import BgServerGoogleplay from "./BgServerGoogleplay";
import { engineVersion } from "mobile-device-detect";



export default class UserConnectorGoogleplay extends UserConnector {
    server:BgServerGoogleplay

    constructor(socket:SocketIOClient.Socket, transport:TransportInterface, server:BgServerGoogleplay)  {                
        super(socket,transport)        
        this.server = server;
        this.rpcUrl = '/yandex-rpc';
    }
    

    async doLogin(signature:string,anonimId:string) {
        return this.__call("login-yandex", {signature,anonim_id:anonimId});
    };

    async doLoginAnonim(anonimId:string) {
        return this.__call("login-yandex", {signature:null, anonim_id:anonimId});
    };    

}