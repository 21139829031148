
export default class ModelArray<T> {

    public models:T[]
    public keyName:string

    public countTotal:number | null

    constructor() {
        this.keyName = "uuid";
        this.models = []
        this.countTotal = null
    }
    
    clear() { 
        this.models = []
        this.countTotal = null;
    }

    get length() {
        return this.models.length
    }

    setCountTotal(value: number) {
        this.countTotal = value
    }
    

    doExpand(expand:T[]) {
        if(!expand.length)
            return;       
        Array.prototype.push.apply(this.models, expand);                       
    }

    doFilterUnique() {
        this.models = this.models.filter( (model, index) => {
            let key = (<any>model)[this.keyName];
            let firstIndex = this.models.findIndex( (e:any)=> e[this.keyName] == key );
            return firstIndex == index;
        })
    }
}