/** \file Mostly derived from BgServerYandex as temporare solution */

import BgServer from "../BgServer";
import { NuxtAxiosInstance } from "@nuxtjs/axios";

import TransportAxios from "../transport/TransportAxios";
//import UserConnectorFacebook from "./UserConnectorFacebook";
//import TradeConnectorFacebook from "./TradeConnectorFacebook";

import { Context } from '@nuxt/types'
import logger from "../utility/logger";
import ModelListCommon from "../ModelListCommon"
import ArraySortedPlayer from "../ArraySortedPlayer";
import TradeConnector from "../connectors/TradeConnector";
import UserConnector from "../connectors/UserConnector";
import UserConnectorGoogleplay from "./UserConnectorGoogleplay";

import { v4 as uuidv4 } from "uuid";
import { IUserInfo } from "../connectors/SocketConnector";
import { sharedStorageLocal } from "../SharedStorage";
class Player {
    getUniqueID(): string {
        //TODO: properly obtain some Googleplay-provided user id:
        return uuidv4()
    }
}


export default class BgServerGoogleplay extends BgServer {
    context:Context    
    purchaseEnable: boolean
    //@ts-ignore
    connectorUserGoogleplay : UserConnectorGoogleplay 

    constructor(axios: NuxtAxiosInstance, context: Context) {
        super(axios);
        this.purchaseEnable = false;
        this.context = context
    }

    async init() {
        await super.init()

        try {
            this.setupLocale()
            await this.doLogin()
        } catch(e) {
            logger.error( "Googleplay login failed", e )
        }
    }

    doLoadId():string {
        const YA_ANONIM_ID_NAME = "ya_anonim_id"
        let id = sharedStorageLocal.get( YA_ANONIM_ID_NAME )
        if ( ! id ) {
            id = get_player_id()
            sharedStorageLocal.set( YA_ANONIM_ID_NAME, id )
        }
        return id;
    }
    
    async doLogin() {
        const anonimId = this.doLoadId()
        console.log( 'Googleplay: performing doLogin with id', anonimId )
        
        return this.connectorUserGoogleplay.doLoginAnonim( anonimId )
    }
    
    createUserConnector(transport:TransportAxios) {        
        // https://yandex.ru/dev/games/doc/dg/sdk/sdk-player.html
        this.connectorUserGoogleplay = new UserConnectorGoogleplay(this.socket, transport, this);       
        this.connectorUserGoogleplay.verbose = true;

        this.connectorUserGoogleplay.on(UserConnector.EVENT_USER_INFO, async (data: IUserInfo)=>{            
            // TODO login even we already logined                        
            this.doAuthroizationAndRetry( async () => this.doLogin() )
        })
        return this.connectorUserGoogleplay
    }

    // TODO use TradeConnectorYandex
    initConnectorTrade() {                        
        const connector : TradeConnector = new TradeConnector(this.socket)
        connector.verbose = process.env.DEBUG_VERBOSE == "true"
        return connector       
    }

    setupLocale() {
        const params = UrlParamsToObject()
        const lang = params[ 'lang' ]
        if ( ! lang || lang.length != 2 ) {
            console.error( "Googleplay: lang param', lang, 'is weird: won't set it." )
            return
        }
        console.log( 'Language URL param was specified as ', lang )

        const i18n = (<any>this.context.app.i18n)
        if(!i18n) {
            logger.warn("Not found i18n module")
            return;
        }
        i18n.setLocale( lang )
        i18n.setLocaleCookie( lang )
    }
}

export function UrlParamsToObject()
{
    const result : any = {};
    const pairs = window.location.href.slice( window.location.href.indexOf( '?' ) + 1 ).split( '&' );

    for ( let i = 0; i < pairs.length; ++i )
    {
        const pair = pairs[ i ].split( '=' );
        let value : any = pair[ 1 ];
        if ( value === undefined )
        {
            value = true;
        }
        result[ pair[ 0 ] ] = value;
    }
    return result;
}

let player_id : string | undefined = undefined
function get_player_id() : string {
    if ( ! player_id ) {
        const params = UrlParamsToObject()
        player_id = params.uuid
        if ( ! player_id ) {
            player_id = uuidv4()
        }
    }
    
    return player_id as string
}


