import BgServer from "../BgServer";
import { NuxtAxiosInstance } from "@nuxtjs/axios";

import TransportAxios from "../transport/TransportAxios";
import TradeConnector from "../connectors/TradeConnector";
import TradeConnectorMailru from "./TradeConnectorMailru";

import { Context } from '@nuxt/types'
import logger from "../utility/logger";
import UserConnector from "../connectors/UserConnector";
import UserConnectorMailru from "./UserConnectorMailru";

import { v4 as uuidv4 } from "uuid";
import { IUserInfo } from "../connectors/SocketConnector";
import { sharedStorageLocal } from "../SharedStorage";
import { space } from '../../modules/space'
import { assert } from "console";
import TradeOffer from "../model/Types/TradeOffer"

interface MailruPlayer {
    hash: string
    status: "ok" | 'error'
    uid: string
    errcode: number
}
declare const mailru : {
    info : MailruPlayer
    onUserInfo : Function
    api : any
}


export default class BgServerMailru extends BgServer {
    context:Context    
    purchaseEnable: boolean
    payments ?: any
    connectorUserMailru ?: UserConnectorMailru
    lastGameEndedUuid : string

    constructor(axios: NuxtAxiosInstance, context: Context) {
        super(axios);
        this.purchaseEnable = false;
        this.context = context;

        this.lastGameEndedUuid = "";

        space.s( 'mailru:user_info' )
            .map( ( info : MailruPlayer ) => !!( info && info.uid ) )
            .to( space.s( 'mailru:is_registered' ) )

        this.maybe_register_on_game_end()

        //@ts-ignore when running locally to debug mailru interoperability, then it's OK to not have global variable mailru initialized:
        if ( window.mailru ) {
            mailru.onUserInfo = ( info : MailruPlayer ) => space.s( 'mailru:user_info' ).next( info )
            space.s( 'mailru:user_info' ).next( mailru.info )
        }
        let imperative_promise_squashing = false
        space.s( 'mailru:try_login' )
            .any( 'mailru:user_info', ( _ : any, info : MailruPlayer ) => info )
            .filter( ( info : MailruPlayer ) => {
                if ( ! this.connectorUserMailru ) {
                    console.log( 'Mailru: cannot proceed to doLoginPlayer because there is still NO connectorUserMailru avaiable.' )
                }
                if ( ! info ) {
                    console.log( 'Mailru: cannot proceed to doLoginPlayer because there is no user info obtained yet from Mailru.' )
                }
                if ( this.connectorUserMailru && info ) {
                    console.log( 'Mailru: will proceed to doLoginPlayer ...' )
                }
                return !!this.connectorUserMailru && !!info
            })
            //.take( 1 )
            .on( ( info : MailruPlayer ) => {
                if ( imperative_promise_squashing ) {
                    console.warn( "Mailru: here we are told to perform another authorization on server, but one is already progressing. It shouldn't be happening on normal program flow. Maybe consider rethinking current implementation. (this another auth attempt is ignored, because current implementation is imperative)" )
                }
                else {
                    imperative_promise_squashing = true
                    const promise = this.doAuthroizationAndRetry( async () => this.doLoginPlayer( info ) )
                    const fail = ( e : any ) => {
                        console.error( "Mailru: eternal server auth process somehow failed, which should NEVER happen.", e )
                        imperative_promise_squashing = false
                    }
                    promise.then(
                        success => imperative_promise_squashing = false,
                        failure => fail,
                    )
                    promise.catch( fail )
                }
            } )
        
        this.purchase_or_register()
    }

    async init() {
        try {
            this.setupLocale()
        } catch(e) {
            logger.warn("Error init sdk mailru", e);
        }
        
        if ( process.env.MAILRU_DEBUG ) {
            console.log( 'Mailru local debug mode is turned on, so will issue predefined mailru credentials in 2 secs ...' )
            setTimeout(
                () => {
                    space.s( 'mailru:user_info' ).next({
                        hash: "5c7eb2c9ca08ba0498b6b900dbbfe7dc",
                        status: "ok",
                        uid: "204477154",
                    })
                },
                2000,
            )
        }

        console.log( 'Mailru: initializing super-server ...' )
        await super.init()
    }

    /** load existed anonimous id or create new one if not exists */
    doLoadAnonimId():string {
        const MAILRU_ANONIM_ID_NAME = "mailru_anonim_id"
        let anonimId = sharedStorageLocal.get( MAILRU_ANONIM_ID_NAME )
        if(!anonimId) {
            anonimId = uuidv4();
            sharedStorageLocal.set( MAILRU_ANONIM_ID_NAME, anonimId )
        }
        return anonimId;
    }

    private async doLoginPlayer( info : MailruPlayer ) {
        if ( ! this.connectorUserMailru ) {
            console.error( 'BgServerMailru::doLoginPlayer: no connector' )
            return
        }

        console.log( 'Mailru: performing doLoginPlayer ...' )
        
        const anonimId = this.doLoadAnonimId()

        let appId : string;
        if ( process.env.MAILRU_APPID ) {
            appId = process.env.MAILRU_APPID
        }
        else {
            console.warn( 'Mailru: NO MAILRU_APPID env variable specified, defaulting to release one!' )
            appId = '5909'
        }

        //if I understand mailru API manual at https://documentation.my.games/f2p/f2pb_js correctly, non-authorized might not mean that PLAYER isn't authed, it just might mean that API wasn't authed, but we'll see:
        if ( info.errcode == 1002 ) {
            console.log( 'Mailru: User was NOT successfully registered within mailru API, so considering it as anonimous.' )
            return this.connectorUserMailru.doLoginMailruAnonim( anonimId )
        }
        else {
            console.log( 'Mailru: logging in as mailru user', info )
            return this.connectorUserMailru.doLoginMailru( info.uid, info.hash, appId, anonimId )
        }
    }
    
    createUserConnector(transport:TransportAxios) {
        this.connectorUserMailru = new UserConnectorMailru( this.socket, transport, this );       
        this.connectorUserMailru.verbose = true

        this.connectorUserMailru.on(UserConnector.EVENT_USER_INFO, (data: IUserInfo) => {
            space.s( 'mailru:try_login' ).next()
        })
        space.s( 'mailru:try_login' ).next()

        return this.connectorUserMailru
    }
    
    initConnectorTrade() {                        
        const connector : TradeConnector = new TradeConnectorMailru( this.socket, this )
        connector.verbose = process.env.DEBUG_VERBOSE == "true"
        return connector
    }

    setupLocale() {
        //const localeShort = this.yandexSdk.environment.i18n.lang
        let localeShort = 'ru'
        try {
            //@ts-ignore
            if ( mailru.params && mailru.params.lang ) {
                //mailru locale comes in as "ru_RU", while Nuxt(i8ln) needs "ru":
                //@ts-ignore
                localeShort = mailru.params.lang.substring( 0, 2 )
                //@ts-ignore
                console.log( 'Mailru: extracted mailru locale', localeShort, 'from', mailru.params.lang )
            }
        }
        catch(e) {
            console.error( 'Mailru: Failed to extract locale from mailru params.' )
        }

        const i18n = (<any>this.context.app.i18n)
        if(!i18n) {
            logger.warn("Not found i18n module");            
            return;
        }

        i18n.setLocale(localeShort);                
        i18n.setLocaleCookie(localeShort);                                 
    }

    async showFullscreenAd() : Promise<boolean | undefined > {
        return new Promise( ( resolve, reject )=>{
            //TODO:
            setTimeout( resolve, 1000 )
        })
    }
    async showRewardedVideo() : Promise<boolean | undefined > {
        return new Promise( ( resolve, reject )=>{
            //TODO:
            setTimeout( resolve, 1000 )
        })
    }

    maybe_register_on_game_end() {
        space.s( 'game_over' )
            .with(
                space.s( 'mailru:is_registered' ),
                space.s( 'mailru:when_asked_to_register' ),
                ( gameUuid : any, r : boolean, when : Date ) => ({gameUuid, r, when })
            )
            .filter(
                ({ gameUuid, r, when }) =>
                    gameUuid !== this.lastGameEndedUuid
                    &&
                    ! r
                    &&
                    //@ts-ignore
                    ( ! when || ( new Date ) - when > 10000 )
            )
            .map( ({ gameUuid, r, when }) => {
                this.lastGameEndedUuid = gameUuid;
                const MAILRU_GAMES_PLAYED = "mailru_games_played"
                const games_played = parseInt( sharedStorageLocal.get( MAILRU_GAMES_PLAYED ) || 0 )
                sharedStorageLocal.set( MAILRU_GAMES_PLAYED, games_played + 1 )
                return games_played
            })
            .to( space.s( 'mailru:ask_to_register' ) )
        space.s( 'mailru:ask_to_register' )
            .map( () => new Date )
            .name( space, 'mailru:when_asked_to_register' )
        
        space.s( 'mailru:ask_to_register' ).on( ( games_played : Number ) => {
            if ( this.context.app.router ) {
                this.context.app.router.push({
                    path : 'askToRegister',
                })
            }
            else {
                console.error( 'Mairu: no router: cannot navigate to registration window.' )
            }
        })

        space.s( 'mailru:tries_to_register' ).to( space.s( 'mailru:register' ) )

        space.s( 'mailru:register' ).on( () => {
            console.log( 'Mailru: user [voluntarily] tries to register (auth?) ...' )
            //mailru.api.registerUser()
            mailru.api.authUser()
        })
    }

    purchase_or_register() {
        space.s( 'mailru:initiate_purchase' )
            .with( space.s( 'mailru:is_registered'), ( p:any, r:any ) => ({ p, r }) )
            .on( ({ p, r }) => {
                if ( r ) {
                    space.s( 'mailru:issue_purchase' ).next( p )
                }
                else {
                    space.s( 'mailru:register' ).next()
                }
            })
        
        space.s( 'mailru:issue_purchase' ).on(  ({ tradeOffer, uuid } : { tradeOffer : TradeOffer, uuid : string }) => {
            try {
                const payment = {
                    merchant_param : {
                        //mailru params:
                        amount : tradeOffer.value_from / 100,
                        currency : tradeOffer.currencyFrom.code_name,
                        description : tradeOffer.quantity,
                        
                        //custom (our) params:
                        trade_offer_uuid : tradeOffer.uuid,
                        player_uuid : uuid,

                        currency_auto_convert : true,
                    },
                }
                logger.log( 'Mailru: Initiating purchase for product', tradeOffer , 'Data:', payment )
                //@ts-ignore
                mailru.api.paymentFrame( payment )
            }
            catch ( e ) {
                //ok: player just cancelled deal:
                //@ts-ignore            
                if ( e.code == 'USER_INPUT' )
                    return "no I didn't purchased"
                throw e
            }
        })
    }
           
}


