import { IUserInfo } from "../connectors/SocketConnector";
import UserConnector from "../connectors/UserConnector";
import TransportInterface from "../transport/TransportInterface";
import BgServerMailru from "./BgServerMailru";
import { engineVersion } from "mobile-device-detect";



export default class UserConnectorMailru extends UserConnector {
    server : BgServerMailru

    constructor( socket:SocketIOClient.Socket, transport:TransportInterface, server:BgServerMailru ) {
        super(socket,transport)
        this.server = server;        
        this.rpcUrl = '/mailru-rpc';
    }

    async doLoginMailru(uuid:string, hash:string, appId:string,anonimId:string) {        
        return this.__call("login-mailru", { uuid, hash, app_id: appId, anonim_id : anonimId } );
    };

    async doLoginMailruAnonim(anonimId:string) {        
        return this.__call("login-mailru", {anonim_id : anonimId } );
    };    

    async doConsumePurchase( signature : string ) {
        return this.__call( "consume-purchase", { signature } )
    }

}