import ModelCollection from "./ModelCollection"

import Tournament,{ETournamentStatus} from "./Types/Tournament";

export default class ModelCollectionTournament extends ModelCollection<Tournament>  {
    ignoreDead:boolean

    constructor(ignoreDead = false) {        
        super();        
        this.ignoreDead = ignoreDead;
    }

    itemDead(model:Tournament) { 
        if(this.ignoreDead)
            return false;
        return model.isDead(); 
    }

    itemCreate(data:object):Tournament { 
        return new Tournament(data);
    }            
}