import Currency, { ICurrency } from "./Currency"
import {EGameKind, IGameRules, IGame} from "./Game"
import {PlayerUuid} from "./Player"
import IPlayerTournament, { IPlayerTournamentWithPlayer, EPlayerTournamentStatus } from "./PlayerTournament";
import { ITour, ITourWithGame } from "./Tour";


export enum ETournamentStatus {
    NONE         = 0,
    ANNOUNCED    = 10,
    OPEN         = 20,
    IN_PLAY      = 30,
    FINISHED     = 40,
    CANCELED     = 50,
}

export enum ETournamentStatusFilter {
    NEAREST     = "nearest", //STATUS_OPEN || STATUS_ANNOUNCED   
    ACTUAL      = "actual",  // STATUS_OPEN || STATUS_ANNOUNCED || STATUS_IN_PLAY
    ACTIVE      = "active",  //STATUS_IN_PLAY
    COMPLETED   = "completed",   // STATUS_CANCELED || STATUS_FINISHED        
}

enum ETournamentType {
    TYPE_ID_PUBLIC = 0,
    TYPE_ID_PRIVATE = 10,
}

export declare type TournamentUuid = string;

export interface ITournamentInRules {
    tourCount: number
    tourIndex: number
}

export interface ITournament {    
    uuid:TournamentUuid        
    bet_price:number    
    win_price : number | null
    type_id: ETournamentType     
    game_type_id: EGameKind
    caption:string    
    description:string|null    
    rules:IGameRules
    max_score:number    
    status: ETournamentStatus    

    created_timestamp:number    
    start_timestamp:number     // when it should be started    
    started_timestamp?:number   // when it started    
    next_timestamp:number      // next tour timestart    
    finished_timestamp?:number  
    
    players_count:number    
    players_count_min:number    
    players_count_max:number

    players_uuid_array: PlayerUuid[]
    
    player_rate_min: number    // 0 if no limit    
    player_rate_max: number    // 0 if no limit
    
    tour_count:number    
    tourCountMax:number    
    
    allow_fb:boolean    
    allow_robot:boolean            
    currencyBet: ICurrency | null   //default exist     
    currencyWin: ICurrency | null
}


export interface ITournamentWithPlayerTournaments extends ITournament {
    playerTournaments: IPlayerTournament[]
} 


export interface ITournamentWithPlayerTournamentsAndWinner extends ITournamentWithPlayerTournaments {    
    playerTournamentWinner: IPlayerTournamentWithPlayer
} 


export interface ITournamentWithAll  extends ITournament {
    playerTournamentWinner: IPlayerTournamentWithPlayer
    playerTournaments: IPlayerTournamentWithPlayer[]    
    tours: ITourWithGame[]
} 



export default class Tournament implements ITournament {
    
    uuid!:TournamentUuid        
    bet_price!:number    
    win_price !: number | null    
    type_id!: ETournamentType             
    game_type_id!: EGameKind    
    caption!:string    
    description!:string|null    
    rules!:IGameRules        
    max_score!:number    
    status!: ETournamentStatus        
    created_timestamp!:number    
    start_timestamp!:number     // when it should be started    
    started_timestamp!:number   // when it started    
    next_timestamp!:number      // next tour timestart    
    finished_timestamp!:number  

    players_count!:number    
    players_count_min!:number    
    players_count_max!:number
    players_uuid_array: PlayerUuid[]    
    player_rate_min!: number    // 0 if no limit    
    player_rate_max!: number    // 0 if no limit
    
    tour_count!:number    
    tourCountMax!:number    
    allow_fb!:boolean    
    allow_robot!:boolean

    playerTournamentWinner !: IPlayerTournamentWithPlayer
    playerTournaments !: IPlayerTournamentWithPlayer[]    
    tours !: ITourWithGame[]
            
    private _currencyBet!:Currency  | null    
    private _currencyWin!:Currency  | null


    constructor(data:any) {
        this.players_uuid_array = [];
        Object.assign(this, data);
        this.player_rate_min =  Number(this.player_rate_min);
        this.player_rate_max =  Number(this.player_rate_max);
        if(!this.players_uuid_array)       
            this.players_uuid_array = [];
    }

    get currencyBet()      {return this._currencyBet}
    set currencyBet(value) { this._currencyBet = value? new Currency(value): null;}

    get currencyWin()      {return this._currencyWin}
    set currencyWin(value) { this._currencyWin = value? new Currency(value): null;}    


    isFinished():boolean { 
        return this.status == ETournamentStatus.FINISHED        
    }    

    isCanceled():boolean { 
        return this.status == ETournamentStatus.CANCELED
    }    

    isDead():boolean { 
        return this.status == ETournamentStatus.FINISHED || this.status == ETournamentStatus.CANCELED;
    }    

    isInPlay():boolean { 
        return this.status == ETournamentStatus.IN_PLAY;
    }   

    isPlayerInPlay(playerUuid:string):boolean  {
        return this.players_uuid_array.length !== undefined && this.players_uuid_array.includes(playerUuid);
    }

    isPlayerPlayTournament(playerUuid:string):boolean {
        //I want to check it somehow easier 
        //there will be paging problems
        if (this.isPlayerInPlay(playerUuid)) {
            if (!this.playerTournaments) {
                return false;
            }
            let player = this.playerTournaments.find(game => {
                return game.player_uuid === playerUuid;
            })
            return player !== undefined && player.status !== EPlayerTournamentStatus.STATUS_CLOSE;
        }
        return false;
    }

    isOpen():boolean {
        return this.status == ETournamentStatus.OPEN
    }

    isJoinable():boolean {
        return this.isOpen() && this.players_count < this.players_count_max;
    }

    isJoinableByPlayer(playerUuid:string):boolean {
        return this.isJoinable() && !this.isPlayerInPlay(playerUuid);
    }

    isRatingOk(value:number) {  
  
        if(this.player_rate_min && this.player_rate_min > value)
            return false;

        if(this.player_rate_max && this.player_rate_max < value)
            return false;

        return true;
    }


}