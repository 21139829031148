import Currency from "./Currency"

export enum ETradeOfferTypeId {
    GIFT    = 10,
    SYSTEM  = 20,
    SYSTEM_ITEM  = 21,
    SYSTEM_COINS  = 22,
    SYSTEM_ITEM_SELL  = 25,
    SYSTEM_REPEAR_KIT  = 26,

    PUBLIC  = 30,
    PRIVATE = 40,
    PURCHASE_FACEBOOK = 50,
    PURCHASE_YANDEX = 60,
    PURCHASE_MAILRU = 70,
}

export declare type TradeOfferUuid = string;

export interface ITradeOffer {
      uuid: TradeOfferUuid      
      created:string      
      created_timestamp:number
        
      valid_after_timestamp:number | null      
      valid_before_timestamp:number | null        
      player_from_uuid:string | null      
      player_to_uuid:string | null              
      currency_from_id:number   // this is what author want's      
      value_from      :number        
      currency_to_id  :number   // this is what you will receive      
      value_to        :number
          
      limit:number | null          // limitiation       
      type_id: ETradeOfferTypeId         
      typeString:string      
      caption:string
    
      product_id: string | null //for fb            
}


export interface ITradeOfferWithCurrency extends ITradeOffer{
    currencyFrom: Currency      
    currencyTo: Currency
    
    force_price_from ?: IFacebookPrice // added for facebook
}

export interface IFacebookPrice {
    price : string
    currency_code : string
}

export default class TradeOffer implements ITradeOfferWithCurrency {
    uuid!:string
    created!:string
    created_timestamp!:number
    valid_after_timestamp!:number | null
    valid_before_timestamp!:number | null
    player_from_uuid!:string | null
    player_to_uuid!:string | null
    currency_from_id!:number   // this is what author want's
    value_from!      :number
    currency_to_id! :number   // this is what you will receive
    value_to!        :number
    limit!:number | null          // limitiation 
    type_id!: ETradeOfferTypeId   
    typeString!:string
    caption!:string
    force_price_from ?: IFacebookPrice

    // canExecute 
    product_id!: string | null

    isAvailable: boolean
    
    private _currencyFrom!: Currency    
    private _currencyTo!: Currency

    constructor(data:any) {
        this.isAvailable = false;
        Object.assign(this, data);                 
    }    

    get quantity() {
        switch ( this.type_id ) {
            case ETradeOfferTypeId.GIFT:
                return this.value_to + " " + this.currencyTo.code_name
            case ETradeOfferTypeId.SYSTEM_ITEM:
                return this.value_from
        }

        if ( this.isCoinsBuy() ) {
            return this.value_to + " " + this.currencyTo.code_name
        }

        return 0
    }
    /** Human-readable price string to be ready to display inside something like shop window.*/
    get price() {
        if ( this.force_price_from ) {
            return this.force_price_from.price
        }

        if ( this.currencyFrom )
            return this.currencyFrom.formatValue( this.value_from, false )

        //if (this.isGift)
        //    return this.$t('shop.BONUS')
        //console.log('#', this.value_from)

        return this.value_from
    }

    get currencyFrom() {return this._currencyFrom}
    set currencyFrom(value:Currency|any) {
        if(typeof value != Currency.prototype.name) 
            value = value ? new Currency(value): value;        
        this._currencyFrom = value;
    }

    get currencyTo() {return this._currencyTo}
    set currencyTo(value:Currency|any) {
        if(typeof value != Currency.prototype.name) 
            value = value ? new Currency(value): value;        
        this._currencyTo = value;
    }

    get isBroke() { 
        return (
            this.isSystemItem() &&
            this._currencyTo.max_value>0 &&
            this.value_to <= 0
        );
    }

    isGift() {return this.type_id == ETradeOfferTypeId.GIFT;}
    isSystemItem() {return this.type_id == ETradeOfferTypeId.SYSTEM_ITEM;}
    isSystemCoin() {return this.type_id == ETradeOfferTypeId.SYSTEM_COINS;}
    isRepearKit() {return this.type_id == ETradeOfferTypeId.SYSTEM_REPEAR_KIT;}

    isFacebook() {return this.type_id == ETradeOfferTypeId.PURCHASE_FACEBOOK} 
    isYandex() { return this.type_id == ETradeOfferTypeId.PURCHASE_YANDEX}
    isMailru() { return this.type_id == ETradeOfferTypeId.PURCHASE_MAILRU }
    
    isCoinsBuy() { return this.isSystemCoin() || this.isFacebook() || this.isYandex() || this.isMailru() }

    isTimeAvailable(serverTimestamp:number) {
        if(!this.valid_after_timestamp && !this.valid_before_timestamp)
            return true;        
                
        if(this.valid_after_timestamp && this.valid_after_timestamp > serverTimestamp)             
            return false;
            
        if(this.valid_before_timestamp && this.valid_before_timestamp < serverTimestamp)             
            return false;        

        return true;
    }
}




// uuid: "8e7eeda7-6c9b-44e2-986d-f376aaa4b1bb"
// created: "2019-11-12 16:19:29+03"
// created_timestamp: 1573564769
// valid_after_timestamp: null
// valid_before_timestamp: null
// player_from_uuid: null
// player_to_uuid: null
// currency_from_id: 643
// value_from: 12000
// currency_to_id: 10011
// value_to: 20000
// limit: null
// type_id: 22
// typeString: "system_coins"
// caption: "system_coins 120.00 RUB to 20000 Cr"
// canExecute: true
// product_id: null
// currencyFrom: {id: 643, entity: "RUSSIAN FEDERATION (THE)", name: "Russian Ruble", code_name: "RUB", minor_unit: 2, …}
// currencyTo: {id: 10011, entity: "5starsgammon", name: "Credit", code_name: "Cr", minor_unit: 0, …}
// __proto__: Object