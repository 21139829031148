import UserConnector from "../connectors/UserConnector";
import TransportInterface from "../transport/TransportInterface";
import BgServerPhoenix from "~/modules/phoenix/BgServerPhoenix";


export default class UserConnectorPhoenix extends UserConnector {
    server:BgServerPhoenix

    constructor(socket:SocketIOClient.Socket, transport:TransportInterface, server:BgServerPhoenix)  {
        super(socket,transport)
        this.server = server;
        this.rpcUrl = '/phoenix-rpc';
    }

    async doLoginPhoenix(terminalId:number) {
        return this.__call("login-phoenix", {terminal_id: terminalId});
    };

    async getActualData() {
        const response = await this.__call('user-info-get', {})
        return response
      };
      
    async doPollingAccount() {
        const newUserData = await this.getActualData() // waiting of finish of async function
        //console.log('POLLING', newUserData)
        // update vuex state or send update event to event bus
        if (newUserData && newUserData.player && newUserData.player.account) {
            this.server.changeAccount(newUserData.player.account)
        }
      
        setTimeout(() => {this.doPollingAccount()}, 10000)
    }
}
