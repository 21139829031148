import ModelCollection from "./ModelCollection"

import TradeOffer from "./Types/TradeOffer";

export default class ModelCollectionTrade extends ModelCollection<TradeOffer>  {
    
    constructor() {
        super();        
    }

    itemDead(item:TradeOffer) {                
        return false;
    }

    itemCreate(data:object){        
        return new TradeOffer(data);
    };
}