import { TournamentUuid, ITournament } from "./Tournament"
import { PlayerUuid, IPlayer } from "./Player"
import { AccountUuid, IAccount } from "./Account"


export enum EPlayerTournamentStatus {
    STATUS_INIT    = 0,
    STATUS_OPEN    = 10,   // ожидание начала турнира, можно отменить свое участие
    STATUS_ACTIVE  = 20,   // идет игра или ожидание тура
    STATUS_CANCEL  = 30,   // участие отменено - деньи возвращаются
    STATUS_DISMISS = 35,   // участие отменено - деньи возвращаются - отменено оператором
    STATUS_CLOSE   = 40,   // участие заверешно
}

export default interface IPlayerTournament {

    id:number
    tournament_uuid:TournamentUuid
    player_uuid:PlayerUuid
    account_uuid:AccountUuid

    created:string
    created_timestamp:number

    modified:string
    modified_timestamp:number

    total_score:number
    status:EPlayerTournamentStatus

    account?:IAccount
    player?: IPlayer
    tournament?:ITournament
}


export interface IPlayerTournamentWithPlayer extends IPlayerTournament {
    player:IPlayer
}
