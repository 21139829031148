import ModelCollection from "./ModelCollection"

import Game,{EGameStatus} from "./Types/Game";

export default class ModelCollectionGame extends ModelCollection<Game>  {
    
    constructor() {
        super();        
    }

    itemDead(game:Game) { 
        return game.isDead(); 
    }

    itemCreate(data:object):Game { 
        return new Game(data);
    }            
}